<template>
  <div class="watch-contents-container">
    <div v-for="(item, i) in watchlistContents" :key="i" class="watch-contents-container-card">
      <categoryCard
        :content="item"
        :cardType="cardDisplayType"
        @play="playContent"
        @remove="removeContent"
        :showRemove="showRemove"
        :showTitle="showTitle"
      ></categoryCard>

      <!-- <div class="functionality-btns"> -->
      <!-- <img class="play-btn" src="@/assets/icons/Ic_Play1.svg" alt /> -->
      <!-- <div class="card-summary">
          <p class="content-title">{{item.title}}</p>
      </div>-->
      <!-- <div class="remove-btn">
          <img src="@/assets/icons/Ic_remove_mylist.svg" @click="removeFromWatchlist(item)" alt />
      </div>-->
      <!-- </div>  -->
      <!-- <div class="collection-icon" v-if="item.category !== 'MOVIE'">
        <img src="@/assets/icons/ic_episodes.svg" alt />
        <span style="color:white" v-if="item.category === 'TVSHOW'">{{item.seasoncount}}</span>
        <span style="color:white" v-if="item.category === 'MUSIC'">{{item.trackcount}}</span>
      </div>-->
    </div>
  </div>
</template>

<script>
import FirebaseActions from "@/mixins/FirebaseActions";
import Utility from "@/mixins/Utility";
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    contents: {
      type: Array,
    },
  },
  data() {
    return {
      watchlistContents: null,
      showRemove: true,
      showTitle: false,
      filteredAvailabilities: [],
      pricemodel: [],
      subscriptions: [],
      availabilities: [],
      purchases: [],
      cardDisplayType: null,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "availabilityList", "subscriptionList", "purchaseList"]),
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
    },
    // watchlistContents(val) {
    //   if (val.length > 0) {
    //     this.fetchCardType();
    //   }
    // }
  },
  created() {
    this.fetchCardType();
    this.watchlistContents = this.contents;
    console.log("watchlistContents", this.watchlistContents);
    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }

    //event for card size check for every screen sizes
    eventBus.$on("re-render-carousel", () => {
      return this.fetchCardType();
    });
  },
  // mounted() {
  //   this.fetchCardType();
  // },
  methods: {
    ...mapActions(["contentDetail"]),
    fetchCardType() {
      if (screen.width <= 425) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "90px",
          width: "165px",
          playButton: { enablePlay: false, width: "40px", height: "40px" },
        };
      } else if (screen.width > 425 && screen.width < 800) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "140px",
          width: "230px",
          playButton: { enablePlay: false, width: "40px", height: "40px" },
        };
      } else {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "160px",
          width: "255px",
          playButton: { enablePlay: false, width: "40px", height: "40px" },
        };
      }
    },
    playContent(content) {
      console.log("watchlist content", content);
      //get the content details for the given content.
      this.contentDetail(content.objectid)
        .then((response) => {
          console.log("THIS BLOCK ENTERED");
          if (!response.data.reason) {
            this.checkAvailability(response.data);
          }
        })
        .catch((error) => console.log(error));
    },
    removeContent(data) {
      this.watchlistContents = null;
      // console.log("remove", data);
      this.$emit("removedWatchlistContent", true);
      this.updateContentWatchlist(data, false);
      // this.watchlistContents = this.watchlistContents.filter(el => {
      //   return el.objectid !== data.objectid;
      // });
    },
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"),
  },
  mixins: [FirebaseActions, Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.watch-contents-container > * {
  // margin: 10px 0 0 15px;
  margin-bottom: 10px;
}
.watch-contents-container {
  // display: inline-flex;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  // margin: -10px 0 0 -10px;
  // width: calc(100% + 10px);
  &-card {
    position: relative;
    // margin-right: 1rem;
    // margin-bottom: 1rem;
    &:hover {
      .functionality-btns {
        opacity: 1;
      }
    }
    .card-summary {
      position: absolute;
      bottom: 2%;
      left: 5%;
      .content-title {
        color: #ffffff;
        font-weight: bold;
        font-family: $font-regular;
      }
    }
    .functionality-btns {
      position: absolute;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.02) 0%,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0.7) 60%,
        rgba(0, 0, 0, 1) 90%
      );
      .play-btn {
        width: 20%;
        cursor: pointer;
      }
      .remove-btn {
        position: absolute;
        left: 2%;
        top: 3%;
        img {
          width: 60%;
          cursor: pointer;
        }
      }
    }
    .collection-icon {
      position: absolute;
      top: 9%;
      right: 0%;
      padding: 5px 15px 5px 5px;
      background: rgba(0, 0, 0, 0.5);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      span {
        padding-left: 5px;
        font-weight: bold;
        font-family: $font-regular;
      }
    }
  }
}
@media only screen and (min-width: 320px) {
  .watch-contents-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 576px) {
  .watch-contents-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 768px) {
  .watch-contents-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (min-width: 1024px) {
  .watch-contents-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
